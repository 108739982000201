<template>
  <ion-page>
    <app-header back="home" title="회원가입" />
    <ion-content :fullscreen="true">
      <div id="container">
        <h3 class="s_tit">이용약관</h3>
        <div class="scrollbox">
          <div class="join_contents">
            <ol>
              <li>
                <strong>제 1 조 (목적)</strong>
                <ol>
                  <li>이 약관은 대한시멘트(이하 “회사”)와 “회사”의 출하 거래처(이하 “회원사”) 및 “회사”의 운송 거래처(이하 “운송사”) 간에 “회사”가 제공하는 고객Portal서비스(이하
                    “서비스”)의 이용과 관련하여 “회사”와 “회원사”, “회사”와 “운송사”간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 2 조 (정의)</strong>
                <ol>
                  <li>
                    (1) 이 약관에서 사용하는 용어의 정의는 다음과 같습니다
                    <ol start="1">
                      <li class="decimal">1. “서비스”라 함은 “회원”이 이용할 수 있는 고객Portal의 제반 서비스를 의미합니다.</li>
                      <li>2. “회원”이라 함은 “회원사” 와 “운송사”를 총칭하는 의미입니다.</li>
                      <li>3. “회원사”라 함은 “회사”와 출하실적이 있는 거래처로써 “회사”의 “서비스”에 접속하여 이 약관에 따라 “회사”와 이용계약을 체결하고 “회사”가 제공하는 “서비스”를
                        이용하는 출하 거래처를 말합니다.</li>
                      <li>4. “회원사 담당”이라 함은 “회원사” 가 지정한 “회원사”의 직원으로서 “서비스”를 이용하는 고객을 말합니다.</li>
                      <li>5. “운송사” 라 함은 “회사”와 운송계약을 체결하고 운송실적이 있는 거래처로써 “회사”의 “서비스”에 접속하여 이 약관에 따라 “회사”와 이용계약을 체결하고 “회사”가
                        제공하는 “서비스”를 이용하는 운송 거래처를 말합니다.</li>
                      <li>6. “운송사 담당” 이라 함은 “운송사” 가 지정한 “운송사”의 직원으로서 “서비스”를 이용하는 고객을 말합니다.</li>
                      <li>7. “아이디(ID)” 라 함은 “회원”의 식별과 “서비스” 이용을 위하여 “회원”이 정하고 “회사”가 승인하는 문자와 숫자의 조합을 의미합니다.</li>
                      <li>8. “비밀번호” 라 함은 “회원”이 부여 받은 “아이디” 와 일치되는 “회원”임을 확인하고 비밀보호를 위해 “회원” 자신이 정한 문자 또는 숫자의 조합을 의미합니다.
                      </li>
                    </ol>
                  </li>
                  <li>(2) 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령 및 “서비스”별 안내에서 정하는 바에 의합니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 3 조 (약관의 게시와 개정)</strong>
                <ol>
                  <li>1. “회사”는 이 약관의 내용을 “회원”이 쉽게 알 수 있도록 “서비스” 초기 화면에 게시합니다.</li>
                  <li>2. “회사”는 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                  <li>3. 개정된 약관은 온라인에서 공지함으로써 효력을 발휘하며, 이용자의 권리 또는 의무 등 중요한 규정의 개정은 시행일로부터 최소 1주일 전에 공지합니다.</li>
                  <li>4. “회사”가 전항에 따라 개정약관을 공지하면서 “회원”이 1주일 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 “회원”이
                    명시적으로 거부의 의사표시를 하지 아니한 경우 “회원”이 개정약관에 동의한 것으로 봅니다.</li>
                  <li>5. 변경된 약관에 대한 정보를 알지 못해 발생하는 “회원”의 피해는 “회사”에서 책임지지 않습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 4 조 (약관의 해석)</strong>
                <ol>
                  <li>1. 이 약관은 “회사”가 제공하는 개별서비스에 관한 이용안내(이하 “서비스별 안내”라 합니다)와 함께 적용합니다.</li>
                  <li>2. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 “서비스별 안내” 및 관계법령 또는 상관례에 따릅니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 5 조 (이용계약의 성립)</strong>
                <ol>
                  <li>1. 이용계약은 “회원”이 되고자 하는 고객(이하 “가입신청자”)이 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 “회사”가 이러한 신청에 대하여 승낙함으로써
                    체결됩니다.</li>
                  <li>2. “가입신청자”가 회원가입신청 당시 이용약관의 “동의함” 버튼을 누름과 동시에 “가입신청자”는 본 이용약관에 동의한 것으로 간주합니다.</li>
                  <li>3. “회원사”의 경우 신청하는 “회원사 담당”에 메뉴, 검색범위 등에 차등을 둘 수 있습니다</li>
                  <li>4. “운송사”의 경우 1항에 추가하여 별도의 전산시스템 사용 및 보안에 관한 서약서(이하 “보안서약서”)를 작성하고 서명한 후 해당 문서 원본을 제출하여야 합니다.</li>
                  <li>5. “가입신청자” 는 “회사”에서 요청하는 제반정보(회사명, 사업자번호, 대표 E-Mail, 담당자이름, 담당자 연락처 등)를 제공하여야 합니다.</li>
                  <li>6. 회원가입은 반드시 실명으로만 가입할 수 있으며 “회사”는 실명확인 및 본인확인 조치를 할 수도 있습니다.</li>
                  <li>7. 이용계약의 성립 시기는 “회사”가 가입완료를 승낙한 시점으로 합니다</li>
                  <li>8. “회사”는 “회원”에 대해 “회사”의 정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</li>
                  <li>9. “회사”는 “회원”이 거래중단 등의 사유로 “회사” 와 관계가 없어진 경우 “회원”에게 사전 통보하지 않고 해당 “아이디” 를 삭제할 수 있습니다.</li>
                  <li>10. “회원”은 담당자의 변경 시 반드시 개인정보관리 화면을 통해 담당자 및 비밀번호변경을 하여야 하며 담당자 및 비밀번호를 변경하지 않아 발생될 수 있는 문제는 제8조에
                    따릅니다.</li>
                  <li>
                    11. “회사”는 아래 사항에 해당하는 경우에 대하여 회원가입 승낙을 하지 않습니다.
                    <ul>
                      <li>“회사” 와 거래가 있는 고객이 아닌 경우</li>
                      <li>실명이 아니거나 타인의 명의를 이용하여 신청한 경우</li>
                      <li>회원가입 신청서의 내용을 허위로 기재한 경우</li>
                      <li>부정한 용도로 본 “서비스”를 이용하고자 하는 경우</li>
                      <li>기타 규정한 제반사항을 위반하며 신청하는 경우</li>
                      <li>보안서약서를 제출하지 아니한 경우(“운송사”에 한 함)</li>
                      <li>해당 “회원사”에 대해 “회사”의 관리 구분에 따라 이미 승인한 “아이디”가 존재하는 경우(“회사”의 관리 구분 예 : 여신그룹, 거래처, 사이트, 현장)</li>
                      <li>해당 “운송사”에 대해 이미 신청한 “아이디”가 존해하는 경우</li>
                    </ul>
                  </li>
                  <li>
                    12. “회사”는 “서비스” 이용신청이 다음 각 호에 해당하는 경우에는 그 신청에 대하여 승낙 제한사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                    <ul>
                      <li>“회사”가 설비의 여유가 없는 경우</li>
                      <li>“회사”의 기술상 지장이 있는 경우</li>
                      <li>기타 “회사”의 사정상 이용승낙이 곤란한 경우</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <strong>제 6 조 (“회원”정보의 변경)</strong>
                <ol>
                  <li>1. “회원”은 회원정보관리화면을 통하여 언제든지 회원정보를 열람하고 수정할 수 있습니다. 다만, “서비스” 관리를 위해 필요한 사업자번호, 아이디 등은 수정이 불가능합니다.
                  </li>
                  <li>2. “회원”은 회원가입신청 시 기재한 사항이 변경되었을 경우 개인정보관리 화면을 통해 수정하여야 합니다.</li>
                  <li>3. 제2항의 변경사항을 수정치 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.</li>
                </ol>
              </li>
              <li>
                제 7 조 (개인정보보호 의무)
                <ol>
                  <li>1. “회사”는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 “회원”의 개인정보를 보호하기 위해 노력합니다.</li>
                  <li>2. 개인정보의 보호 및 사용에 대해서는 관련법 및 “회사”의 개인정보처리방침이 적용됩니다. 다만, “회사”의 공식 사이트 이외의 링크 된 사이트에서는 “회사”의 개인정보처리방침이
                    적용되지 않습니다.</li>
                </ol>
              </li>
              <li>
                제 8 조 (“회원”의 “아이디” 및 “비밀번호” 의 관리에 대한 의무)
                <ol>
                  <li>1. “회원”의 “아이디”와 “비밀번호”에 관한 관리책임은 “회원”에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.</li>
                  <li>2. “회원”은 “회원사 담당” 또는 “운송사 담당” 이 퇴사 등의 사유로 변경 시 반드시 회원정보변경화면을 통해 담당자 변경 및 비밀번호 변경을 하여야 합니다.</li>
                  <li>3. 1항 및 2항을 소홀이 관리하여 발생하는 “서비스” 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 “회원”에게 있으며 “회사”는 그에 대한 책임을 일절 지지
                    않으며 이로 인해 “회사”에 손해가 미친 경우 관련법에 의해 손해배상을 청구할 수 있습니다.</li>
                  <li>4. “회사”는 “회원”의 “아이디”가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 “회사” 및 “회사”의 운영자로 오인할 우려가 있는 경우, 해당
                    “아이디”의 이용을 제한할 수 있습니다.</li>
                  <li>5. “회원”은 “아이디” 및 “비밀번호” 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야 합니다.</li>
                  <li>6. “회원”은 3개월 주기로 "비밀번호" 를 변경하여야 하고 “회사”는 “비밀번호” 변경 팝업을 띄워야 합니다.</li>
                  <li>7. 제5항의 경우에 해당 “회원”이 “회사”에 그 사실을 통지하지 않거나, 통지한 경우에도 “회사”의 안내에 따르지 않아 발생한 불이익에 대하여 “회사”는 책임지지 않습니다.
                  </li>
                  <li>
                    8. “아이디”는 다음 각 호에 해당하는 “회사”의 요청으로 변경할 수 있습니다.
                    <ul>
                      <li>“아이디”가 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 사생활침해가 우려되는 경우</li>
                      <li>타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</li>
                      <li>기타 합리적인 사유가 있는 경우</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <strong>제 9 조 (“회원”에 대한 통지)</strong>
                <ol>
                  <li>1. “회사”가 “회원”에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 “서비스” 내 공지사항, 전자우편 등으로 할 수 있습니다.</li>
                  <li>2. “회사”는 “회원” 전체에 대한 통지의 경우 7일 이상 “회사”의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 10 조 (“회사”의 의무)</strong>
                <ol>
                  <li>1. “회사”는 이용고객이 희망한 “서비스” 제공 개시일에 특별한 사정이 없는 한 “서비스”를 이용할 수 있도록 최선의 노력을 하여야 합니다. 단, “회사”의 정책 상 “서비스”
                    의 철거 등의 사유로 이용을 종료하는 경우 사전 공지 후에 이용을 종료할 수 있습니다.</li>
                  <li>2. “회사”는 “회원”으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는
                    “회원”에게 그 사유와 처리일정을 통보하여야 합니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 11 조 (이용자의 의무)</strong>
                <ol>
                  <li>1. “회원” 회원가입 신청 또는 회원정보 변경 시 실명으로 모든 사항을 사실에 근거하여 작성하여야 하며, 허위 또는 타인의 정보를 등록할 경우 일체의 권리를 주장할 수 없습니다.
                  </li>
                  <li>2. “회원”은 본 약관에서 규정하는 사항과 기타 “회사”가 정한 제반 규정, 공지사항 등 “회사”가 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 “회사”의 업무에 방해가
                    되는 행위, “회사”의 명예를 손상시키는 행위를 해서는 안됩니다.</li>
                  <li>3. “회사”가 관계법령 및 '개인정보 보호정책'에 의거하여 그 책임을 지는 경우를 제외하고 “회원”에게 부여된 “아이디” 및 “비밀번호” 관리소홀, 부정사용에 의하여 발생하는
                    모든 결과에 대한 책임은 “회원”에게 있습니다.</li>
                  <li>4. “회원”은 “회사”의 사전 승낙 없이 “서비스”를 이용하여 영업활동을 할 수 없으며, 그 영업활동의 결과에 대해 “회사”는 책임을 지지 않습니다. 또한 “회원”은 이와 같은
                    영업활동으로 “회사”가 손해를 입은 경우, “회원”은 “회사”에 대해 손해배상의무를 지며, “회사”는 해당 “회원”에 대해 “서비스” 이용제한 및 적법한 절차를 거쳐 손해배상 등을
                    청구할 수 있습니다.</li>
                  <li>5. “회원”은 “회사”와 업무 수행함에 있어 알게 된 영업비밀 등 기타 모든 “회사” 경영에 관련된 정보를 제3자에게 제공하거나, 공개 또는 누설하지 않고 “회사”의 사전 동의
                    없이 무단사용하지 않습니다. 또한 이로 인한 “회사”가 손해를 입은 경우, “회원”은 “회사”에 대해 손해배상의무를 지며, “회사”는 해당 “회원”에 대해 “서비스” 이용제한 및
                    적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.</li>
                  <li>6. “회원”은 “회사”의 명시적 동의가 없는 한 “서비스”의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여할 수 없으며 이를 담보로 제공할 수 없습니다.</li>
                  <li>7. “회원”은 “서비스” 이용을 위해 명시적으로 접근을 허가 받은 정보만 이용하여야 하며, “서비스” 를 통해 수행하는 모든 업무 내용을 “회사”가 실시간 또는 사후 검색할 수
                    있음을 동의해야 합니다.</li>
                  <li>
                    8. “회원”은 다음 각 호에 해당하는 행위를 하여서는 안되며, 해당 행위를 하는 경우에 “회사”는 “회원”의 “서비스” 이용제한 및 적법 조치를 포함한 제재를 가할 수 있습니다.
                    <ul>
                      <li>회원가입 신청 또는 회원정보 변경 시 허위내용을 등록하는 행위</li>
                      <li>다른 “회원”의 “아이디”, 비밀번호를 도용하는 행위</li>
                      <li>“회원” “아이디”를 타인과 거래하는 행위</li>
                      <li>“서비스”에 위해를 가하거나 고의로 방해하는 행위</li>
                      <li>본 “서비스”를 통해 얻은 정보를 “회사”의 사전 승낙 없이 “서비스” 이용 외의 목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제 3자에게 제공하는 행위
                      </li>
                      <li>본 약관을 포함하여 기타 “회사”가 정한 제반 규정 또는 이용 조건을 위반하는 행위</li>
                      <li>기타 관계법령에 위배되는 행위</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <strong>제 12 조 (“서비스”의 제공 등)</strong>
                <ol>
                  <li>
                    “회사”는 “회원”에게 아래와 같은 “서비스”를 무상으로 제공합니다.
                    <ul>
                      <li>출하정보 검색 서비스(“회원사” 출하실적 조회 등)</li>
                      <li>배차관리 서비스(“운송사” 배차실적, 관리 등)</li>
                      <li>기타 “회사”가 추가 개발 등을 통해 “회원”에게 제공하는 일체의 “서비스”</li>
                    </ul>
                  </li>
                  <li>2. “서비스”는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다</li>
                  <li>3. “회사”는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 “서비스”의 제공을 일시적으로 중단할 수 있습니다. 이 경우
                    “회사”는 제9조에 정한 방법으로 “회원”에게 통지합니다. 다만, “회사”가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.</li>
                  <li>4. “회사”는 “서비스”의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 “서비스”제공화면에 공지한 바에 따릅니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 13 조 (“서비스”의 변경)</strong>
                <ol>
                  <li>1. “회사”는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 “서비스”를 변경할 수 있습니다.</li>
                  <li>2. “회사”는 “서비스”의 일부 또는 전부를 “회사”의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한 규정이 없는 한 “회원”에게 별도의
                    보상을 하지 않습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 14 조 (정보의 제공 및 광고의 게재)</strong>
                <ol>
                  <li>1. “회사”는 “회원”이 “서비스” 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 “회원”에게 제공할 수 있습니다.</li>
                  <li>2. “회사”는 “서비스”의 운영과 관련하여 “서비스” 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 “회원”은 수신거절을
                    “회사”에게 할 수 있습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 15 조 ("게시물"의 관리)</strong>
                <ol>
                  <li>1. “회원”은 “게시물”이 “정보통신망법” 및 “저작권법”등 관련법에 위반되는 내용을 게시해서는 안됩니다.</li>
                  <li>2. “회사”는 “서비스”의 운영과 관련하여 “서비스” 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을 수신한 “회원”은 수신거절을
                    “회사”에게 할 수 있습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 16 조 (계약해제, 해지 등)</strong>
                <ol>
                  <li>1. “회원”은 언제든지 “서비스” 초기화면의 회원정보관리 메뉴 등을 통하여 이용계약 해지 신청을 할 수 있으며, “회사”는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야
                    합니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 17 조 (이용제한 등)</strong>
                <ol>
                  <li>
                    1. “회사”는 “회원”이 “서비스” 이용내용에 있어서 본 약관 제 11조 내용을 위반하거나, 다음 각 호에 해당하는 경우 “서비스” 이용을 제한할 수 있습니다.
                    <ul>
                      <li>타 이용자에게 심한 모욕을 주거나, “서비스” 이용을 방해한 경우</li>
                      <li>기타 정상적인 “서비스” 운영에 방해가 될 경우</li>
                      <li>정보통신 윤리위원회 등 관련 공공기관의 시정 요구가 있는 경우</li>
                      <li>3개월 이상 “서비스”를 이용한 적이 없는 경우</li>
                      <li>타 “아이디”를 도용하여 접근하는 경우</li>
                      <li>자신의 ”아이디”를 타인에게 제공하는 경우</li>
                    </ul>
                  </li>
                  <li>2. 상기 이용제한 규정에 따라 “서비스”를 이용하는 “회원”에게 “서비스” 이용에 대하여 별도 공지 없이 “서비스” 이용의 일시정지, 초기화, 이용계약 해지 등을 취할 수
                    있습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 18 조 (손해배상)</strong>
                <ol>
                  <li>1. “회사”는 “회원”의 “서비스”의 이용과 관련하여 발생하는 어떠한 손해도 책임을 지지 않습니다.</li>
                  <li>2. “서비스”를 활용한 “회원”의 영업활동으로 “회사”가 손해를 입은 경우, “회원”은 “회사”에 대해 손해배상의무를 지며, “회사”는 해당 “회원”에 대해 “서비스” 이용제한
                    및 적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.</li>
                  <li>3. “회원”은 “회사”와 업무 수행함에 있어 알게 된 영업비밀 등 기타 모든 “회사” 경영에 관련된 정보를 제3자에게 제공하거나, 공개 또는 누설하지 않고 “회사”와 사전 동의
                    없이 무단사용하지 않습니다. 또한 이로 인한 “회사”가 손해를 입은 경우, “회원”은 “회사”에 대해 손해배상의무를 지며, “회사”는 해당 “회원”에 대해 “서비스” 이용제한 및
                    적법한 절차를 거쳐 손해배상 등을 청구할 수 있습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 19 조 (책임제한)</strong>
                <ol>
                  <li>1. “회사”는 “회원”이 “서비스”를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않습니다.</li>
                  <li>2. “회사”는 “회원”이 “서비스”를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다. 또한 “회사”는 “회원”이 “서비스”를 이용하며 타 “회원”으로 인해 입게
                    되는 정신적 피해에 대하여 보상할 책임을 지지 않습니다.</li>
                  <li>3. “회사”는 “회원”이 “서비스”에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않습니다.</li>
                  <li>4. “회사”에서 “회원”에게 무료로 제공하는 “서비스”의 이용과 관련해서는 어떠한 손해도 책임을 지지 않습니다.</li>
                </ol>
              </li>
              <li>
                <strong>제 20 조 (관할법원)</strong>
                <ol>
                  <li>1. “회사”와 “회원”간 발생한 법적 분쟁은 광주지방법원을 관할법원으로 합니다.</li>
                </ol>
              </li>
              <li>
                <strong>부칙</strong>
                <ol>
                  <li>1. 이 약관은 2023년 01월 01부터 적용됩니다.</li>
                </ol>
              </li>
            </ol>
          </div>

        </div>
        <div class="check_boxW tr bt_no">
          <label><input type="radio" name="joinChk" value="Y" /> 동의함</label> &nbsp;&nbsp;&nbsp; <label><input type="radio"
              name="joinChk" value="N" /> 동의하지 않음</label>
        </div>
        <h3 class="s_tit">개인정보처리방침</h3>
        <div class="scrollbox">
          <div class="join">
            <div class="join_contents">
              <p>대한시멘트(이하 “회사”라 합니다)는 정보주체의 개인정보보호를 매우 중요시 하며 ‘개인정보보호법’ 등 관련 법규를 준수하기 위해 ‘개인정보처리방침’을 제정하고 이를 준수하고 있습니다.
                회사는 개인정보처리방침을 통하여 정보주체가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있고 개인정보보호를 위해 어떠한 조치가 취해지고 있는지를 알려드립니다.</p>
              <p>이 개인정보처리방침의 순서는 다음과 같습니다.</p>
              <p></p>
              <ol>
                <li>
                  제1조 <a href="/dhc/terms#n1">수집하는 개인정보의 항목 및 수집방법</a>
                </li>
                <li>
                  제2조 <a href="/dhc/terms#n2">개인정보의 수집 및 이용목적</a>
                </li>
                <li>
                  제3조 <a href="/dhc/terms#n3">개인정보의 보유 및 이용기간</a>
                </li>
                <li>
                  제4조 <a href="/dhc/terms#n4">개인정보의 파기절차 및 그 방법</a>
                </li>
                <li>
                  제5조 <a href="/dhc/terms#n5">개인정보의 제공 및 공유</a>
                </li>
                <li>
                  제6조 <a href="/dhc/terms#n6">수집한 개인정보의 취급위탁제</a>
                </li>
                <li>
                  제7조 <a href="/dhc/terms#n7">정보주체의 권리.의무 및 그 행사방법</a>
                </li>
                <li>
                  제8조 <a href="/dhc/terms#n8">개인정보 자동수집장치의 설치, 운영 및 그 거부에 관한 사항</a>
                </li>
                <li>
                  제9조 <a href="/dhc/terms#n9">개인정보 보호책임자</a>
                </li>
                <li>
                  제10조 <a href="/dhc/terms#n10">개인정보의 안전성 확보조치</a>
                </li>
                <li>
                  제11조 <a href="/dhc/terms#n11">개인정보처리방침 변경에 따른 고지</a>
                </li>
              </ol>
              <hr>
              <ol class="a_none">
                <li>
                  <a id="n1">제 1 조 수집하는 개인정보의 항목 및 수집방법</a>
                  <ul>
                    <li>회사는 회원가입을 통해 성명, 아이디, 비밀번호, 회사명, 이메일 주소, 사업자등록번호, 주소, 연락처, 휴대폰번호, 팩스번호를 수집하고 있습니다.</li>
                    <li>고객 Portal의 경우 운송사가 원활한 서비스를 이용하기 위해서 Offline으로 제출하는 보안서약서를 통해 성명, 회사명, 대표이사명, 회사주소, 사업자등록번호, 전화번호,
                      이메일, 담당자명, 휴대폰전화 정보가 수집될 수 있습니다.</li>
                    <li>또한 서비스 이용과정이나 사업처리 과정에서 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록, 이용정지 기록 정보들이 생성되어 수집될 수 있습니다.</li>
                    <li>회사는 정보주체에게 각종 서비스를 제공하고, 정보주체의 본인확인을 위해 다음의 방법을 통해 개인정보를 수집하고 있습니다.</li>
                    <li>또한 온라인 서비스 이용과정이나 회사의 업무처리과정에서 서비스 이용기록, 접속로그, 쿠키, 접속IP정보, 결제기록, 이용정지 기록정보 들이 생성되어 수집될 수 있습니다.
                    </li>
                    <li>단, 주민등록번호는 원칙적으로 수집을 금지하되, 아래와 같은 경우는 예외적 수집할 수 있습니다.</li>
                    <br> &nbsp;• 법령에서 구체적으로 주민번호 처리를 요구, 허용한 경우
                    <br> &nbsp;• 정보주체 또는 제 3자의 급박한 생명, 신체, 재산의 이익을 위해 명백히 필요한 경우
                    <br> &nbsp;• 기타 주민번호 처리가 불가피한 경우로서 안전행정부령으로 정하는 경우
                    <br>
                    <ul class="no_bl">
                      <li>[홈페이지 회원가입 및 관리]</li>
                      <li>― 필수항목 : 성명, 성별, 생년월일, 아이디, 비밀번호, 주소, 연락처, 이메일 주소 등</li>
                    </ul>
                    <ul class="no_bl">
                      <li>[재화 또는 서비스의 제공]</li>
                      <li>― 필수항목 : 성명, 주소, 연락처</li>
                    </ul>
                    <ul class="no_bl">
                      <li>[수집 방법]</li>
                      <li>― 홈페이지, 서면양식 등을 통한 수집</li>
                    </ul>
                  </ul>
                </li>
                <li>
                  <a id="n2">제 2 조 (개인정보의 수집 및 이용목적)</a>
                  <ul>
                    <li>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 정보주체가 제공한 모든 정보는 다음에 기술한 목적 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할
                      것입니다.</li>
                    <ul class="no_bl">
                      <li>[홈페이지 회원가입 및 관리]</li>
                      <li>회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부
                        확인, 각종 고지.통지, 고충처리 등</li>
                    </ul>
                    <ul class="no_bl">
                      <li>[재화 또는 서비스의 제공]</li>
                      <li>물품 배송, 서비스 제공, 계약서.청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인 인증, 연령 인증, 대금결제.정산, 채권추심 등</li>
                    </ul>
                    <ul class="no_bl">
                      <li>[내부 임.직원]</li>
                      <li>인사관리, 급여관리, 업무관리 등</li>
                    </ul>
                  </ul>
                </li>
                <li>
                  <a id="n3">제 3 조 (개인정보의 보유 및 이용기간)</a>
                  <ul>
                    <li>정보주체의 요청이 있거나 개인정보의 수집 또는 제공받은 목적이 달성된 때에는 정보주체의 개인정보를 지체 없이 파기합니다. 다만, 정보주체의 요청이 있는 경우에도 다음 목적에
                      의한 범위 내에서는 정보주체의 개인정보를 보관합니다.</li>
                    <ul class="no_bl">
                      <li>[홈페이지 회원가입 및 관리]</li>
                      <li>― 홈페이지 탈퇴 시까지</li>
                      <li>(홈페이지 이용에 따른 채권.채무관계 잔존 시에는 해당 채권.채무 정산 시까지)</li>
                      <li>― 정부주체에게 별도의 보유기간 동의를 받은 경우 탈퇴이후에도 별도 동의</li>
                      <li>에서 정한 기간까지</li>
                    </ul>
                    <ul class="no_bl">
                      <li>[재화 또는 서비스의 제공]</li>
                      <li>- 재화.서비스 공급 완료 및 대금 결제.정산 완료 시까지</li>
                      <li>- 계약, 대금결제, 소비자 분쟁 관련 기록 등은 관련 법률에 따라 5년간 별도보관</li>
                    </ul>
                    <ul class="no_bl">
                      <li>[[내부 임.직원]</li>
                      <li>― 임.직원 정보는 퇴사 후 경력증명 용도로 보존</li>
                      <li>(단, 계약종료 후 본인 요청 시 해당 정보 파기)</li>
                    </ul>
                  </ul>
                </li>
                <li>
                  <a id="n4">제 4 조 (개인정보의 파기절차 및 그 방법)</a>
                  <ul>
                    <li>회사의 개인정보 파기절차와 방법은 다음과 같습니다.</li>
                    <ul class="no_bl">
                      <li>― 파기 절차</li>
                      <li>개인정보의 수집 및 이용목적이 달성된 후에는 즉시 해당 개인정보를 파기합니다.</li>
                      <li>다만 내부방침 및 기타 관련법령에 따라(보유 및 이용기간 참조) 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 DB로 옮기거나 보관장소를 달리하여
                        보존하며, 동 개인정보는 법률에 의한 경우가 아니고서는 보유 되는 것 이외의 다른 목적으로 이용되지 않습니다.</li>
                      <li>― 파기 방법</li>
                      <li>전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통해 파기 합니다.
                      </li>
                    </ul>
                  </ul>
                </li>
                <li>
                  <a id="n5">제 5 조 (개인정보의 제공 및 공유)</a>
                  <ul>
                    <li>회사는 정보주체의 개인정보를 원칙적으로 제3자에게 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.</li>
                    <ul class="no_bl">
                      <li>― 정보주체의 동의가 있는 경우</li>
                      <li>― 법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                    </ul>
                  </ul>
                </li>
                <li>
                  <a id="n6">제 6 조 (수집한 개인정보의 취급위탁)</a>
                  <ul>
                    <li>회사는 정보주체의 동의 없이 개인정보 취급을 외부 업체에 위탁하지 않습니다. 향후 위탁업무의 내용이나 수탁자가 추가되는 경우 본 개인정보처리방침 또는 기타 적절한 방법을 통하여
                      공개하도록 하겠습니다.</li>
                    <li>위탁업무의 내용이나 수탁자가 변경 또는 추가되는 경우 본 개인정보처리방침 또는 기타 적절한 방법을 통하여 공개하도록 하겠습니다.</li>
                  </ul>
                </li>
                <li>
                  <a id="n7">제 7 조 (정보주체의 권리.의무 및 그 행사방법)</a>
                  <ul>
                    <li>정보주체는 회사에 대해 언제든지 등록되어 있는 정보주체의 개인정보를 열람하거나 정정, 삭제, 처리정지를 요청하실 수 있습니다.</li>
                    <li>제1항에 따른 권리 행사는 회사에 대해 개인정보보호법 시행규칙 별지 제8호서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체
                      없이 조치하겠습니다.</li>
                  </ul>
                </li>
                <li>
                  <a id="n8">제 8 조 (개인정보 자동수집장치의 설치, 운영 및 그 거부에 관한 사항)</a>
                  <ul>
                    <li>회사는 회원님의 정보를 수시로 저장하고 찾아내는 쿠키(Cookie)를 운영합니다.</li>
                    <li>쿠키란 대한시멘트 고객포털에 접속 시 자동으로 회원님의 컴퓨터로 전송되는 아주 작은 용량의 텍스트파일로서 회원님의 컴퓨터 하드디스크에 암호화되어 저장됩니다.</li>
                    <ul class="no_bl">
                      <li>― 회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.</li>
                      <ul class="no_bl">
                        <li>회사는 사용자 환경설정 등에서도 쿠키를 사용합니다. 개인이 직접 선택한사용 환경 값을 저장해 두었다가 사용자가 필요로 하는 환경설정을 위하여 이 정보를 사용합니다.
                        </li>
                        <li>쿠키는 대한시멘트 고객포털에서 로그인 시 인증을 위해서 사용되며 ID저장을 위해서 사용됩니다. 이러한 쿠키들을 이용하여 회원님이 방문한 홈페이지 서비스의 이용형태를
                          파악함으로써 더 유용하고 이용하기 편리한 서비스를 만들어 제공할 수 있게 됩니다.</li>
                      </ul>
                      <ul class="no_bl">
                        <li>회원님은 쿠키에 대한 선택권을 갖고 있습니다. 회원님의 웹 브라우저에서 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의
                          저장을 거부할 수 있습니다. 단 쿠키의 저장을 거부 하실 경우 로그인이 필요한 일부 서비스는 이용하실 수 없게 됩니다.</li>
                      </ul>
                    </ul>
                    <li>*설정방법 (예, 인터넷 익스폴로러 : 웹 브라우저 상단의 도구 &gt; 인터넷옵션 &gt; 개인정보)</li>
                  </ul>
                </li>
                <li>
                  <a id="n9">제 9 조 (개인정보 보호책임자)</a>
                  <ul>
                    <li>회사는 정보주체의 개인정보를 보호하기 위하여 각 부문의 담당임원이 개인정보 책임자로 지정되어 있으며 개인정보와 관련한 대표 문의처 대한시멘트
                      영업관리팀(061-798-8542)으로
                      문의 바랍니다.</li>
                    <li>
                      <a id="n10">제 10 조 (개인정보의 안전성 확보조치)</a>
                      <ul>
                        <li>회사는 정보주체의 개인정보 보호를 위한 기술적 대책으로서 여러 보안장치를 마련하고 있습니다. 정보주체께서 보내시는 모든 정보는 방화벽 등 보안시스템에 의해 안전하게
                          보관/관리되고 있습니다. 또한 회사는 정보주체의 개인정보보호를 위한 관리적 대책으로서 정보주체의 개인정보에 대한 접근 및 관리에 필요한 절차를 마련하고, 정보주체의
                          개인정보를 취급하는 인원을 최소한으로 제한하여 지속적인 보안교육을 실시하고 있습니다. 또한 개인정보를 처리하는 시스템의 사용자를 지정하여 사용자 비밀번호를 부여하고 이를
                          정기적으로 갱신하겠습니다.</li>
                      </ul>
                    </li>
                    <li>
                      <a id="n11">제 11 조 (개인정보처리방침 변경에 따른 고지)</a>
                      <ul>
                        <li>본 방침은 2023년 01월 01일에 제정되었으며 법률의 제·개정, 정부의 정책 변경, 회사 내부방침의 변경 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이
                          있을 시에는 변경되는 개인정보처리방침을 시행하기 최소 7일 전에 회사 홈페이지를 통해 변경이유 및 내용을 개정 최소 7일 전에 공지하도록 하겠습니다.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="check_boxW tr bt_no">
          <label><input type="radio" name="joinChk2" value="Y" /> 동의함</label> &nbsp;&nbsp;&nbsp; <label><input
              type="radio" name="joinChk2" value="N" /> 동의하지 않음</label>
        </div>
        <div>
          <ion-button color="medium" @click="sendApply">다음</ion-button>
        </div>
      </div>
    </ion-content>
</ion-page>
</template>

<script setup>


import {
  IonContent,
  IonPage,

  IonButton,
} from '@ionic/vue';
// import { chevronForwardOutline } from 'ionicons/icons';
import router from '@/router';
import { toast } from '@/utils/toast';


const sendApply = async () => {

  const joinChk = document.querySelector('input[name="joinChk"]').checked;
  const joinChk2 = document.querySelector('input[name="joinChk2"]').checked;
  if (joinChk == true && joinChk2 == true) {
    router.push('/register')
  } else if (!joinChk || !joinChk2) {
    toast('동의하지 않은 약관이 있습니다. 모든 약관에 동의하셔야 회원가입신청이 진행 됩니다.')
  }
}
</script>

<style scoped>
#container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0.5em;
}

ion-button {
  width: 100%;
  margin: 1.5em 0;
  height: 3em;
}

.join_contents a{
  color: black;
  text-decoration: none;
}
.join_contents a:hover{
  color:#d4331b;
  text-decoration:none;
}

</style>